import * as routes from "constants/Routes";

import { Suspense, lazy, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

import LazyLoader from "components/LazyLoader";
import { ROLES } from "constants/Role";
import { useSelector } from "react-redux";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

const Home = lazy(() => import("pages/Home"));
const Login = lazy(() => import("pages/Login"));
const ForgotPass = lazy(() => import("pages/ForgotPass"));
const ChangePass = lazy(() => import("pages/ChangePass"));
const ClientDashboard = lazy(() => import("pages/Client"));
const SuperDashboard = lazy(() => import("pages/SuperAdmin"));
const AdminDashboard = lazy(() => import("pages/Admin"));
const NotFound = lazy(() => import("pages/NotFound"));

/**
 * Top level application router
 *
 * @returns {Component}
 */

export const Router = (): React.ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();
  const authObj = useSelector((state: any) => state.auth);
  const { isAuthenticated, role } = authObj;

  useEffect(() => {
    if (role !== null) {
      let url = `${ROLES[role]}/dashboard`;
      const getLastLocation = localStorage.getItem("location");
      if (getLastLocation && getLastLocation !== undefined) {
        url = getLastLocation;
      }
      navigate(url);
    }
    // eslint-disable-next-line
  }, [role]);

  useEffect(() => {
    const privateRoutes = location.pathname.split("/");
    if (
      location?.pathname && role && privateRoutes[1]?.includes(ROLES[role])
    ) {
      localStorage.setItem("location", location.pathname);
    }
    // eslint-disable-next-line
  }, [location]);

  return (
    <Routes>
      <Route
        path={routes.HOME}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PublicRoute isAuthenticated={isAuthenticated}>
              <Home />
            </PublicRoute>
          </Suspense>
        }
      />
      <Route
        path={routes.LOGIN}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PublicRoute isAuthenticated={isAuthenticated}>
              <Login />
            </PublicRoute>
          </Suspense>
        }
      />
      <Route
        path={routes.FORGOTPASSWORD}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PublicRoute isAuthenticated={isAuthenticated}>
              <ForgotPass />
            </PublicRoute>
          </Suspense>
        }
      />
      <Route
        path={routes.RESETPASSWORD}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PublicRoute isAuthenticated={isAuthenticated}>
              <ChangePass />
            </PublicRoute>
          </Suspense>
        }
      />
      <Route
        path={`${routes.APP}/*`}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <ClientDashboard />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path={`${routes.SUPERADMIN}/*`}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <SuperDashboard />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path={`${routes.ADMIN}/*`}
        element={
          <Suspense fallback={<LazyLoader />}>
            <PrivateRoute isAuthenticated={isAuthenticated}>
              <AdminDashboard />
            </PrivateRoute>
          </Suspense>
        }
      />
      <Route
        path="*"
        element={
          <Suspense fallback={<LazyLoader />}>
            <NotFound />
          </Suspense>
        }
      />
    </Routes>
  );
};
