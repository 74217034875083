import { ChangeEvent, useState } from "react";

import { MIMETYPE } from "constants/MimeType";
import { useForm } from "react-hook-form";
import { UploadData } from "types";
import Spinner from "./Loader/Spinner";

const AddMedia = ({
  loader,
  onConfirm,
}: {
  loader: boolean;
  onConfirm: Function;
}) => {
  const [file, setFile] = useState<Blob | null>();
  const [fileText, setFileText] = useState<string>("Choose from Library");
  const [fileError, setFileError] = useState<string | "">("");

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<UploadData>();

  const validateFile = (e: ChangeEvent<HTMLInputElement>) => {
    setFileError("");
    setFile(null);
    const fileList = e.target.files;
    if (fileList) {
      const type = fileList[0]?.type.toLowerCase();
      if (MIMETYPE.includes(type)) {
        setFileError("");
      } else {
        setFileError("Only JPG, PNG, WEBP, MP4, PDF is allowed");
      }
      setFileText(fileList[0].name || "Choose from Library");
      setFile(fileList[0]);
    }
  };

  const onFormSubmit = async (formData: UploadData, e: any) => {
    setFileError("");
    e.preventDefault();
    if (file) {
      onConfirm({ name: formData.name, file });
    } else {
      setFileError("Please add media file");
    }
  };

  return (
    <>
      <h2 className="border-gray border-b p-3 text-lg font-semibold text-gray-600">
        Upload Media
      </h2>
      <div className="p-3">
        <span className="text-sm text-gray-500">
          Please fill out the form below to add new media
        </span>
        <div className="w-full pt-2">
          <form
            className="space-y-4"
            onSubmit={handleSubmit(onFormSubmit)}
            noValidate
          >
            <div>
              <label htmlFor="name" className="sr-only">
                File Name
              </label>
              <input
                {...register("name", { required: true })}
                id="name"
                name="name"
                type="text"
                autoComplete="name"
                required
                className="relative block w-full appearance-none  rounded-md border border-gray-300 p-3 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-brand-500 focus:outline-none focus:ring-brand-500 sm:text-sm"
                placeholder="Your file name"
                autoFocus
              />
              {errors.name && (
                <span className="text-sm text-red-500">
                  File Name is required
                </span>
              )}
            </div>
            <div className="rounded-md border-2 border-dashed p-4">
              <label
                htmlFor="file-upload"
                className="relative flex cursor-pointer flex-col items-center justify-center font-semibold text-brand-600 hover:text-brand-500 focus:outline-none"
              >
                <span>{fileText}</span>
                <input
                  id="file-upload"
                  name="file"
                  type="file"
                  className="sr-only"
                  accept="image/png,image/jpeg,image/webp,image/jpg,application/pdf"
                  onChange={validateFile}
                  multiple={false}
                />
              </label>
              <span className="flex items-center justify-center px-4 pb-2 text-xs font-semibold text-gray-400">
                Only JPG, PNG, WEBP, MP4, PDF is allowed
              </span>
            </div>
            {fileError !== "" && (
              <div className="text-sm text-red-500">{fileError}</div>
            )}
            <div>
              <button
                type="submit"
                className="group relative flex w-full justify-center rounded-md border border-transparent bg-brand py-3 px-4 text-sm font-medium text-white hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-brand-800 focus:ring-offset-2"
                disabled={loader}
              >
                {loader ? (
                  <div>
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                      <Spinner />
                    </span>
                    <span className="tracking-wider">PLEASE WAIT...</span>
                  </div>
                ) : (
                  <div>
                    <span className="tracking-wider">Upload</span>
                  </div>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
export default AddMedia;
