import { memo, useEffect, useState } from "react";
import { Template, TemplateComponents } from "types";

import WEXTLogo from 'assets/images/logo.png';
import FileIcon from "components/FileImage";
import DOMPurify from "dompurify";
import markdownFormat from "helper/markdownFormat";
import { Templates } from "helper/template";
import url from "helper/url";
import { cloneDeep } from "lodash";
import { useSelector } from "react-redux";

const TemplateValue = ({
  data,
  template,
}: {
  data: any;
  template: Template;
}): JSX.Element => {
  const authObj = useSelector((state: any) => state.auth);
  const { user } = authObj;

  const [value, setValue] = useState<Template>(template);
  const cloneTemplate = cloneDeep(template);

  useEffect(() => {
    if (data) {
      const count = Templates.checkParamater(cloneTemplate);
      const updatedValue = Templates.setParameterValue(
        data,
        cloneTemplate,
        count
      );
      setValue(updatedValue);
    }
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="relative z-10 flex flex-col text-sm">
      <h3 className="pb-2 font-semibold text-black">Preview</h3>
      <div className="relative flex flex-col space-y-2 rounded-md rounded-tl-none bg-white p-4 shadow-sm">
        <span className="bubble-arrow left-arrow text-white">
          <svg viewBox="0 0 8 13" width="8" height="13">
            <path
              opacity=".13"
              fill="#0000000"
              d="M1.533 3.568 8 12.193V1H2.812C1.042 1 .474 2.156 1.533 3.568z"
            ></path>
            <path
              fill="currentColor"
              d="M1.533 2.568 8 11.193V0H2.812C1.042 0 .474 1.156 1.533 2.568z"
            ></path>
          </svg>
        </span>
        {value?.components?.map((m: TemplateComponents) => {
          return (
            <div key={m.type}>
              {m.type === "HEADER" &&
                (m.format === "TEXT" || m.format === "text") && (
                  <span className="header whitespace-pre-wrap font-semibold">
                    {m.text}
                  </span>
                )}
              {m.type === "HEADER" &&
                (m.format === "IMAGE" || m.format === "image") && (
                  <img
                    alt={`${user?.name}' Chat`}
                    loading='lazy'
                    src={m?.image?.link || WEXTLogo}
                    className={`${m?.image?.link ? 'h-32 w-full' : 'w-12 h-12 grayscale'} rounded-md`}
                  />
                )}
              {m.type === "HEADER" &&
                (m.format === "VIDEO" || m.format === "video") && (
                  <span className="font-semibold text-black">
                    {m?.video?.link
                      ? url.getFilename(`${m?.video?.link}`)
                      : "Video File"}
                  </span>
                )}
              {m.type === "HEADER" &&
                (m.format === "DOCUMENT" || m.text === "document") && (
                  <a
                    title={m?.document?.link}
                    href={m?.document?.link}
                    className="flex items-center space-x-2 rounded-md bg-gray-100 p-2 lowercase"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FileIcon path={`${m?.document?.link}`} />
                    <span className="font-semibold text-black">
                      {m?.document?.link
                        ? url.getFilename(`${m?.document?.link}`)
                        : "Document File"}
                    </span>
                  </a>
                )}
              {m.type === "BODY" && (
                <p
                  className="body whitespace-pre-wrap p-1"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      markdownFormat(m.text) as string
                    ),
                  }}
                />
              )}
              {m.type === "FOOTER" && (
                <span
                  className="footer whitespace-pre-wrap p-1 text-gray-500"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      markdownFormat(m.text) as string
                    ),
                  }}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default memo(TemplateValue);
